import * as React from "react";
import { FunctionComponent } from "react";
import { Link, Heading, Page } from "../components";

import "./Privacy.css";

const PrivacyPage: FunctionComponent = (): JSX.Element => (
  <Page title="Privacy" layout="default">
    <div className="tile--two-thirds">
      <Heading level={1} style={{ textAlign: "center" }}>
        Online Privacy Policy
      </Heading>
      <div className="tile info-section">
        <p>
          Hawaiiana Management Company, Ltd. (“HAWAIIANA,” “us,” “we,” or “our”)
          respects the privacy and security of your personally identifiable
          information that we collect about you though your use of our website
          and the services provided at hmcmgt.com (collectively, the
          “Services”). The following describes our privacy policy and practices
          with respect to that information. By your registration to use or use
          of the Services, you consent to our collection and use of your
          personally identifiable information and other information online by us
          in the manner set forth in this Online Privacy Policy. Please read
          this policy carefully. We may change this policy from time to time,
          and we will post those changes on this page, your continued use
          following the posting of any amendment will constitute your acceptance
          to that amendment. Please check back periodically. This policy will
          notify you of:
        </p>
        <ol>
          <Link href="#1">
            <li>What is &quot;personally identifiable information&quot;?</li>
          </Link>
          <Link href="#2">
            <li>
              What personally identifiable information we collect about you
              through our online services?
            </li>
          </Link>
          <Link href="#3">
            <li>Who is collecting the information?</li>
          </Link>
          <Link href="#4">
            <li>How the information is used? </li>
          </Link>
          <Link href="#5">
            <li>Who do we share the information with?</li>
          </Link>
          <Link href="#6">
            <li>
              Your choices as to collection, use and distribution of your
              personally identifiable information?
            </li>
          </Link>
          <Link href="#7">
            <li>
              How you can correct inaccuracies in or update your information?
            </li>
          </Link>
          <Link href="#8">
            <li>
              What security procedures are in place to protect the loss, misuse
              or alteration of the information?
            </li>
          </Link>
          <Link href="#9">
            <li>General</li>
          </Link>
        </ol>
        <p>
          If you have any questions concerning our Online Privacy Policy, please
          contact us at:
        </p>
        <p>
          <Link href="mailto:onlineSupport@hmcmgt.com">
            OnlineSupport@hmcmgt.com
          </Link>{" "}
          Hawaiiana Group Incorporated
          <br />
          711 Kapiolani Blvd., Suite 711
          <br />
          Honolulu, HI 96813
          <br />
          (808) 593-6824
        </p>

        <ol>
          <li>
            <Heading level={3} id="1">
              What is &quot;personally identifiable information?&quot;
            </Heading>
            <p>
              Personally identifiable information is information that can be
              used to identify you as a unique individual, such as your name;
              home, email or other address; telephone number; birth date; credit
              card number; and similar personal information.
            </p>
          </li>
          <li>
            <Heading level={3} id="2">
              What personally identifiable information do we collect about you
              through our online services
            </Heading>
            <p>
              We collect personally identifiable information about you in a
              number or different ways through our online Services. Your
              association may give us personally identifiable information about
              you to permit us to verify your identity as a person who is
              entitled to register to use and to continue to use the Services we
              provide to you and your association. When you register to use
              Services, we will ask for certain personally identifiable
              information to confirm your identity as a person authorized to use
              the Services. This information is required to be complete and
              accurate at the time it is given, and to be updated so that it is
              at all times accurate and complete, as a condition of your
              continued ability to use the Services. Once you have registered to
              use the Services, whenever you sign in to use the Services your
              personal identity is known to us. Other personally identifiable
              information about you may be given to us by the association or you
              in order to render the Services. For example, we may assist the
              association in compiling an electronic database of members of the
              association, or posting an online directory of members. You may
              give your credit card number to us through the Services in order
              to pay your association&#39;s dues or assessments. However, prior
              to its transmission to our bank or your association&#39;s bank,
              your credit card information is encrypted through the use of
              secure server software and is then transmitted in this secure and
              encrypted fashion. Your credit card information is not retained by
              us. If you send an email message, we will receive your email
              address and any other personally identifiable information you may
              include in that message.
            </p>
          </li>
          <li>
            <Heading level={3} id="3">
              Who is collecting the information
            </Heading>
            <p>
              As explained above, we collect personally identifiable information
              about you in connection with the provision of our Services.
              Contractors that we engage to provide some of the Services may
              also collect personally identifiable information about you in
              connection with the performance of those Services. We require
              those contractors to adhere to privacy policies no less stringent
              than our Online Privacy Policy. Web sites that are linked to our
              site may also collect personally identifiable information about
              you. We have no control over the information gathering practices
              of those sites and they are not covered by our Online Privacy
              Policy. We encourage you to read the privacy policies of any such
              third party. In the course of using the Services, you may provide
              personally identifiable information to others. For example, you
              might post a message to a message board or send an email to
              another member, your association or the association&#39;s
              management company, that may contain personally identifiable
              information. We do not control the privacy practices or policies
              of any such person or entity, and they are not covered by this
              Online Privacy Policy.
            </p>
          </li>
          <li>
            <Heading level={3} id="4">
              How is the information used
            </Heading>
            <p>
              {" "}
              We use your personally identifiable information that we collect
              through your use of the Services to provide the Services, and to
              help us improve our Services. We may share anonymous information,
              or information that is &quot;aggregated,&quot; to better
              understand the users of our Services and enhance or improve our
              Services, but we will do so only in a manner that does not
              personally identify you. We may use your personally identifiable
              information to send you messages about the Services.
            </p>
          </li>
          <li>
            <Heading level={3} id="6">
              Who do we share the information with
            </Heading>
            <p>
              We share your personally identifiable information with your
              association on whose behalf we are providing the Services. We also
              share the information with affiliates, vendors, or third parties
              that may provide a portion of the Services. Our affiliated
              companies have the same information rights that we have. However,
              we do not allow third party vendors to use that information other
              than in connection with the provisions of the Services without
              your express consent. Third party vendors include parties who help
              us with specialized services, including customer support, email
              and text message deployment, business analytics, marketing,
              suppression list management, and data processing. We may share
              your personally identifiable information in connection with a
              substantial business transaction, such as the sale of HAWAIIANA, a
              merger, consolidation, asset sale, or in the unlikely event of
              bankruptcy. We may disclose personally identifiable information to
              any third party if required to do so by law or regulation, or if
              we believe in good faith that such disclosure is necessary to: (a)
              comply with a judicial proceeding, court order, or other legal
              process; (b) enforce the Terms of Service; (c) respond to claims
              that any content of any portion of the Services violated the
              rights of third parties; or (d) protect the rights, property, or
              personal safety of us, other Users or the public. ‍Other Websites;
              Links Our website and software may include links to third party
              advertisements and promotions. We will not provide any personally
              identifiable information without your express consent in relation
              to these linked advertisements and promotions. In addition to such
              third party advertisements and promotions, certain websites of
              HAWAIIANA affiliates and other third-party website are accessible
              through our website. These third-party websites have their own
              privacy and data collection policies and practices. HAWAIIANA IS
              NOT RESPONSIBLE FOR ANY ACTIONS OR PRIVACY POLICIES OF SUCH THIRD
              PARTIES. HAWAIIANA DOES NOT ENDORSE, RECOMMEND, OR COMMENT ON THE
              SUITABILITY OF ANY THIRD- PARTY PRODUCT OR SERVICE. YOU SHOULD
              CHECK THE APPLICABLE PRIVACY POLICIES OF THOSE THIRD PARTIES WHEN
              PROVIDING PERSONALLY IDENTIFIABLE INFORMATION THROUGH LINKED
              WEBSITES. ‍Consent to Use of Third Party Providers In addition to
              the specific third parties noted in this Online Privacy Policy, we
              may also contract with other third parties to provide various
              services to HAWAIIANA relating to our website on an outsourced
              basis rather than performing the services ourselves. By using our
              online Services, you consent to us providing both personal and
              non-personal information received to these third-party providers
              for the purpose of enabling the third-party provider to provide
              these outsourced services to HAWAIIANA. In addition, you consent
              to the collection, maintenance, and processing of your personal
              and non-personal information by HAWAIIANA and these third-party
              providers.
            </p>
          </li>
          <li>
            <Heading level={3} id="7">
              How you can correct inaccuracies in or update your information
            </Heading>
            <p>
              Personally identifiable information about you may be contained in
              an online database maintained in connection with provision of the
              Services. Your personally identifiable information that is
              contained in any such database should be accessible by you online,
              if you wish to correct any inaccuracies, as long as you are an
              authorized User of the Services. Obviously, certain personally
              identifiable information, such as the address of the “unit” that
              you own, cannot be changed without verification by your
              association or its management company. We are not responsible for
              information contained in such online databases. We may maintain
              our own separate databases in order to provide the Services, to
              which you will not have online access. For example, we may
              maintain one or more databases of members of an association who
              are entitled to become and remain users of the Services. Such
              databases may contain information furnished by the association,
              its management company and/or members, or otherwise collected as
              set forth in this Online Privacy Policy. If you wish to ask us
              about the personally identifiable information that we may have
              collected about you and that is not available to you online, you
              should email us at OnlineSupport@hmcmgt.com. We will use
              commercially reasonable efforts to comply with your request, and
              to update and/or correct any personally identifiable information
              that is contained within such databases.
            </p>
          </li>
          <li>
            <Heading level={3} id="8">
              What security procedures are in place to protect the loss, misuse
              or alteration of the information
            </Heading>
            <p>
              Access to the Services, including to databases containing
              personally identifiable information, is password protected. Any
              personal information that you have posted in any portion of the
              Services (e.g. a message board) should be accessible only by the
              association, us, other members and other authorized Users of the
              Services. It is the responsibility of each User to protect the
              User&#39;s password. Do not divulge it to anyone. Log off your
              computer or close your browser window to ensure that others cannot
              access your password. We use industry-standard software, hardware
              and other measures, to protect the confidentiality, security and
              quality of information we collect, and to protect it from
              unauthorized access or disclosure or accidental loss, alteration
              or destruction. Due to the nature of the Internet and computer
              security, we cannot warrant or guarantee that security will never
              be breached.
            </p>
          </li>
          <li>
            <Heading level={3} id="9">
              General
            </Heading>
            <p>
              {" "}
              This Online Privacy Policy is governed by the internal substantive
              laws of the State of Hawaii, without regard to its conflict of
              laws principals. Jurisdiction for any disputes, controversies, and
              claims arising under, in connection with, or out of this Policy
              shall lie exclusively with the state and federal courts located in
              Honolulu, Hawaii. If any provision of this Online Privacy Policy
              is found to be invalid by a court of competent jurisdiction, the
              invalidity of such provision shall not affect the validity of the
              remaining provisions of this Online Privacy Policy, which shall
              remain in full force and effect. UNDER NO CIRCUMSTANCES SHALL
              HAWAIIANA HAVE ANY LIABILITY WHATSOEVER FOR ANY CONSEQUENTIAL,
              INCIDENTAL, INDIRECT, OR SPECIAL DAMAGES ARISING OUT OF THIS
              ONLINE PRIVACY POLICY, HOWEVER OCCASIONED.
            </p>
          </li>
        </ol>
      </div>
    </div>
  </Page>
);

export default PrivacyPage;
